import cloneDeep from 'lodash/cloneDeep'; // eslint-disable-line you-dont-need-lodash-underscore/clone-deep
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { PLAN_UPGRADE_URL } from '@core/constants/urls';
import { AppMetaContext, BaseUrlContext } from '@core/context';
import useReadmeApi from '@core/hooks/deprecated/useReadmeApi';
import useUserPermissions from '@core/hooks/useUserPermissions';
import { useSuperHubStore } from '@core/store';
import classy from '@core/utils/classy';

import EmptyState from '@routes/SuperHub/components/EmptyState';

import Button from '@ui/Button';
import Modal, { ModalBody, ModalFooter } from '@ui/Modal';
import Notification, { NotificationToaster, notify } from '@ui/Notification';
import Title from '@ui/Title';
import Toggle from '@ui/Toggle';

import TutorialCard from './components/Card';
import TutorialHero from './components/Hero';
import TutorialModal from './components/Modal';
import { DEFAULT_TUTORIAL } from './components/Modal/constants/stepDefaults';
import TutorialObstacle from './components/Obstacle';
import { cmVariableContext, PermissionContext } from './PermissionContext';
import classes from './style.module.scss';
import useApiActions from './useApiActions';

export default function Tutorials(props) {
  const ApiActions = useApiActions();
  const [isSuperHub, isSuperHubAdmin, isEditing, apiBaseUrl] = useSuperHubStore(s => [
    s.isSuperHub,
    s.isSuperHubAdmin,
    s.isEditing,
    s.apiBaseUrl,
  ]);
  const { updateAppMeta } = useContext(AppMetaContext);
  const baseUrlCtx = useContext(BaseUrlContext);
  const baseUrl = isSuperHub ? apiBaseUrl.split('/versions')[0] : props.baseUrl || baseUrlCtx;
  const { modalTarget, confirmationTarget, slug, referenceEnabled, isFreePlan, stagingUrl } = props;
  const history = useHistory();
  const { isAdminUser } = useUserPermissions();

  const [hero, setHero] = useState({});
  const [onboarding, setOnboarding] = useState({});
  const [tutorials, setTutorials] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  const [modalMeta, setModalMeta] = useState({
    open: false,
    action: 'View',
  });
  const [confirmModalState, setConfirmModal] = useState({});
  const [moduleEnabled, setModuleEnabled] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState();

  const modalRef = React.createRef();

  const { response, error, setError, pendingRequest, initRequest } = useReadmeApi(baseUrl);
  const getTutorials = () => ApiActions.getTutorials(initRequest);

  useEffect(() => {
    getTutorials();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pendingRequest) getTutorials();
  }, [pendingRequest]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(response).length) setInitialLoad(false);
  }, [response]);

  useEffect(() => {
    if (response.hero) setHero(response.hero);
  }, [response.hero]);

  useEffect(() => {
    if (response.tutorialOnboarding) setOnboarding(response.tutorialOnboarding);
  }, [response.tutorialOnboarding]);

  useEffect(() => {
    if (response.tutorials) setTutorials(response.tutorials);
  }, [response.tutorials]);

  useEffect(() => {
    if ('moduleEnabled' in response) setModuleEnabled(response.moduleEnabled);
  }, [response.moduleEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (slug) {
      ApiActions.getTutorial(initRequest, slug);
      setTimeout(() => getTutorials());
    }
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (response.tutorial && !response.tutorialPublishToggled) {
      setSelectedTutorial(response.tutorial);
      setModalMeta({
        open: true,
        action: 'View',
      });
    }
  }, [response.tutorial]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateAppMeta({
      title: selectedTutorial?.title || 'Recipes',
      type: 'tutorials',
      description: selectedTutorial?.description,
    });
  }, [selectedTutorial?.title, selectedTutorial?.description, updateAppMeta]);
  const showTutorials = (!!hero && !!hero.title) || tutorials.length > 0;

  /** Determines whether admin toolbar controls should be shown or not. */
  const showAdminTools = isSuperHub ? isEditing && showTutorials : isAdminUser;

  const pushState = useCallback(
    pathSlug => {
      const urlRegexp = /https?:\/\//;

      if (!urlRegexp.test(baseUrl)) {
        const uri = pathSlug ? `/recipes/${pathSlug}` : '/recipes';
        history.push(`${isSuperHub && isEditing ? '/update' : ''}${uri}`);
      }
    },
    [baseUrl, history, isEditing, isSuperHub],
  );

  const openTutorial = useCallback(
    (action, tutorial) => {
      setModalMeta({
        open: true,
        action,
      });
      // Allow us to differentiate based on two factors
      // What type of modal action is this? View|Create|Update
      // And is this the first time we're opening it?
      setSelectedTutorial(prevTutorial => {
        const prevId = prevTutorial?._id?.toString();
        const newId = tutorial?._id?.toString() ?? '';

        // If our action is create, and we don't have matching IDs, we can assume we're starting with a fresh recipes
        if (prevId !== newId && action === 'Create') {
          return { ...cloneDeep(DEFAULT_TUTORIAL) };
          // Otherwise, we want to wipe the existing recipe context and start with freshly pulled data
        } else if (prevId !== newId) {
          pushState(tutorial.slug);
          return { ...tutorial };
        }
        // If we're opening the same modal, we don't want to overwrite any previous work
        pushState(tutorial.slug);
        return prevTutorial;
      });
    },
    [pushState],
  );

  const closeTutorialModal = () => {
    pushState();
    setModalMeta(prevMeta => {
      return {
        ...prevMeta,
        open: false,
      };
    });
  };

  const publishTutorial = event => {
    const { published } = event;

    modalRef.current.toggle(true);
    return setConfirmModal({
      title: `Are you sure you want to ${published ? 'publish' : 'unpublish'} this Recipe?`,
      body: published ? 'This will make it viewable all users.' : 'It will no longer be viewable to users.',
      action: ApiActions.updateTutorial.bind(null, initRequest, event),
      buttonText: published ? 'Publish It!' : 'Unpublish Recipe',
    });
  };

  const deleteTutorial = event => {
    modalRef.current.toggle(true);
    return setConfirmModal({
      title: `Are you sure you want to delete ${event.title ? `‘${event.title}’` : 'this Recipe'}?`,
      body: 'You cannot undo this action.',
      action: ApiActions.deleteTutorial.bind(null, initRequest, event),
      buttonText: 'Delete Recipe',
    });
  };

  const makeHero = event => {
    const { _id, published } = event;

    if (published) return ApiActions.makeHero(initRequest, { _id, slug: event.slug });

    modalRef.current.toggle(true);
    return setConfirmModal({
      title: 'Are you sure you want to feature an unpublished Recipe?',
      body: 'Featuring an unpublished Recipe will publish it. Make sure it’s ready!',
      action: ApiActions.makeHero.bind(null, initRequest, { _id, slug: event.slug }),
      buttonText: 'Feature It!',
    });
  };

  let formattedError = error;
  if (error && DOMParser) {
    try {
      const doc = new DOMParser().parseFromString(error, 'text/html');
      formattedError = doc.firstElementChild.innerText;
    } catch (e) {
      formattedError = 'Unexpected error. Please contact support@readme.io!';
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  useEffect(() => {
    if (!!isAdminUser && !!formattedError) {
      notify(
        <Notification dismissible handleClick={() => setError(null)} kind="error" position="top">
          <p>{formattedError}</p>
        </Notification>,
        {
          position: 'top',
        },
      );
    }
  }, [isAdminUser, formattedError, setError]);

  const ToolbarControls = useCallback(
    () => (
      <div className={classy(classes.TutorialToolbar, isSuperHub && classes.TutorialToolbar_superhub)}>
        {!isSuperHub && (
          <button className={classes['TutorialToolbar-Button']} type="Button">
            {moduleEnabled ? (
              <i className={`icon icon-eye-2 ${classes['TutorialToolbar-Button-Icon']}`} />
            ) : (
              <i className={`icon icon-eye-off ${classes['TutorialToolbar-Button-Icon']}`} />
            )}
            <span>
              Show Recipes
              <i className={`fa fa-chevron-down ${classes['TutorialToolbar-Button-Arrow']}`} />
            </span>
            <div className={classes['TutorialToolbar-PopoverWrapper']}>
              <div className={classes['TutorialToolbar-Popover']}>
                <Toggle
                  checked={moduleEnabled}
                  disabled={!tutorials.length || isFreePlan}
                  label="Show Recipes"
                  onChange={() => ApiActions.toggleModule(initRequest)}
                  type="toggle"
                />
                {isFreePlan ? (
                  <div className={classes['TutorialToolbar-Popover-Description']}>
                    Recipes are only available on paid plans. Upgrade your plan to make this section visible to users.{' '}
                    <a
                      className={classes['TutorialToolbar-Popover-Description-Button']}
                      href={PLAN_UPGRADE_URL}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Upgrade
                    </a>
                  </div>
                ) : (
                  <div className={classes['TutorialToolbar-Popover-Description']}>
                    Make your Recipes page visible to users. This must be enabled to see embedded Recipes in Guides or
                    API References.
                  </div>
                )}
              </div>
            </div>
          </button>
        )}
        {isSuperHub ? (
          <Button onClick={() => openTutorial('Create')}>New Recipe</Button>
        ) : (
          <button className={classes['TutorialToolbar-Button']} onClick={() => openTutorial('Create')} type="Button">
            <i className={`icon icon-recipes-new ${classes['TutorialToolbar-Button-Icon']}`} />
            Create Recipe
          </button>
        )}
      </div>
    ),
    [ApiActions, initRequest, isFreePlan, isSuperHub, moduleEnabled, openTutorial, tutorials.length],
  );

  const StagingToolbarControls = useCallback(
    () => (
      <div className={classy(classes.TutorialToolbar, isSuperHub && classes.TutorialToolbar_superhub)}>
        {/* This should be an <a> tag with an href,
                  or using the Button component, instead of the onClick
                  but none of those looked right without some CSS rewrites.
                  This is not ideal but is okay for now.
               */}
        <button
          className={classes['TutorialToolbar-Button']}
          onClick={() => {
            window.open(stagingUrl, '_blank');
          }}
          type="Button"
        >
          <i className={`icon icon-recipes-new ${classes['TutorialToolbar-Button-Icon']}`} />
          Edit Recipes on Staging
        </button>
      </div>
    ),
    [isSuperHub, stagingUrl],
  );

  return (
    <PermissionContext.Provider value={isSuperHub ? isEditing : isAdminUser}>
      <cmVariableContext.Provider value={props.variables}>
        <BaseUrlContext.Provider value={baseUrl}>
          <main
            className={classy(
              'rm-Recipes',
              classes.Tutorial,
              !!initialLoad && classes.Tutorial_loading,
              isSuperHub && classes.Tutorial_superhub,
            )}
            id="content"
          >
            <div className={classes['Tutorial-Wrapper']}>
              {!initialLoad &&
                !showTutorials &&
                (isSuperHub ? (
                  // Render updated empty state only on superhub.
                  <EmptyState onAction={isSuperHubAdmin ? () => openTutorial('Create') : undefined} section="recipe" />
                ) : (
                  <TutorialObstacle
                    body="Recipes are task-driven guidance for your API. Try creating a new Recipe for your users!"
                    className={!!modalMeta.open && 'paused'}
                    heading="Create a Recipe to Get Started"
                  >
                    {stagingUrl ? (
                      <Button href={stagingUrl} target="_blank">
                        Edit Recipes on Staging
                      </Button>
                    ) : (
                      <Button onClick={() => openTutorial('Create')}>Create Recipe</Button>
                    )}

                    <Button
                      bem={{ shale: true }}
                      href="https://docs.readme.com/recipes/publish-all-docs-in-category"
                      target="_blank"
                    >
                      Try Example
                    </Button>
                  </TutorialObstacle>
                ))}
              {!!showTutorials && (
                <React.Fragment>
                  {!!hero && !!hero.title && <TutorialHero hero={hero} openTutorial={openTutorial} />}
                  <section className={classes.TutorialGrid}>
                    {tutorials.map((tutorial, idx) => (
                      <TutorialCard
                        key={`tut-${idx}`}
                        className={classes['TutorialGrid-Card']}
                        deleteTutorial={deleteTutorial}
                        makeHero={makeHero}
                        numTutorials={tutorials.length}
                        openTutorial={openTutorial}
                        publish={publishTutorial}
                        shiftOrder={e => ApiActions.shiftOrder(initRequest, e, idx)}
                        stagingUrl={stagingUrl}
                        tutorial={tutorial}
                      />
                    ))}
                  </section>
                </React.Fragment>
              )}
            </div>

            {!!showAdminTools && !modalMeta.open && !stagingUrl && <ToolbarControls />}
            {!!showAdminTools && !modalMeta.open && !!stagingUrl && <StagingToolbarControls />}

            {!!isFreePlan && (
              <div className={`${classes.TutorialFooter} hub-footer`}>
                <a className="hub-notification" href={PLAN_UPGRADE_URL} id="recipe-notification">
                  <i className="icon icon-alert-circle" />
                  Recipes is only available on paid plans. Upgrade to make it public.
                </a>
              </div>
            )}
          </main>
          <TutorialModal
            action={modalMeta.action}
            baseUrl={baseUrl}
            closeTutorialModal={closeTutorialModal}
            confirmModal={modalRef}
            onboarding={onboarding}
            open={modalMeta.open}
            referenceEnabled={referenceEnabled}
            requestDataRefresh={() => getTutorials()}
            setConfirmModal={setConfirmModal}
            target={modalTarget}
            tutorial={selectedTutorial}
          />
          <Modal
            ref={modalRef}
            className={classes.TutorialConfirmation}
            size="sm"
            target={confirmationTarget}
            verticalCenter
          >
            <ModalBody>
              <Title className={classes['TutorialConfirmation-Title']} level={4}>
                {confirmModalState.title}
              </Title>
              <p className={classes['TutorialConfirmation-Description']}>{confirmModalState.body}</p>
            </ModalBody>
            <ModalFooter justify="center">
              <Button
                bem={{ shale_text: true }}
                onClick={() => {
                  setConfirmModal({});
                  modalRef.current.toggle(false);
                }}
              >
                Cancel
              </Button>
              <Button
                bem={confirmModalState.buttonText === 'Delete Tutorial' ? { red: true } : { blue: true }}
                onClick={() => {
                  confirmModalState.action();
                  modalRef.current.toggle(false);
                }}
              >
                {confirmModalState.buttonText}
              </Button>
            </ModalFooter>
          </Modal>
          <div
            className={classy('ModalWrapper', isSuperHub && classes.SuperHubModalWrapper)}
            id={modalTarget.startsWith('#') ? modalTarget.slice(1) : modalTarget}
          />
          <div
            className="ModalWrapper"
            id={confirmationTarget.startsWith('#') ? confirmationTarget.slice(1) : confirmationTarget}
          />
          <NotificationToaster />
        </BaseUrlContext.Provider>
      </cmVariableContext.Provider>
    </PermissionContext.Provider>
  );
}

Tutorials.propTypes = {
  baseUrl: PropTypes.string,
  confirmationTarget: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  isFreePlan: PropTypes.bool,
  modalTarget: PropTypes.string,
  referenceEnabled: PropTypes.bool,
  slug: PropTypes.string,
  stagingUrl: PropTypes.string,
  variables: PropTypes.shape({
    defaults: PropTypes.arrayOf(PropTypes.any),
    user: PropTypes.any,
  }),
};

Tutorials.defaultProps = {
  baseUrl: '',
  confirmationTarget: '#confirmation-root',
  isAdmin: false,
  modalTarget: '#modal-root',
  referenceEnabled: false,
  variables: {
    defaults: [],
    user: {},
  },
};
